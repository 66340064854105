<!--
 * @Author: Link
 * @Date: 2021-08-20 13:46:40
 * @LastEditTime: 2021-08-30 20:11:31
-->
<template>
  <div class="overview-data">
    <div class="item-box" style="padding: 15px" v-for="item in platformData" :key="item.name">
      <div class="data-item">
        <div class="title">{{ item.name }}</div>
        <div class="content">
          <div class="icon">
            <img :src="require(`@/asset/icon/overview/platform/${item.icon}.png`)" alt="" srcset="" />
          </div>
          <div class="data" v-if="data[item.key]">
            <div class="data-num">{{ data[item.key].value }}</div>
            <div class="yesterday">
              昨日 {{ data[item.key].yesterday }}
              <img :src="require(`@/asset/icon/overview/trend_${data[item.key].trend ? 'up' : 'down'}.png`)" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['rowData'],
  data() {
    return {
      data: {},
      platformData: [
        {
          name: '交易金额', //'交易金额',
          icon: 'money',
          key: 'todayMoney'
        },
        {
          name: '支付订单数', //'支付订单数',
          icon: 'order',
          key: 'todayOrderCount'
        },
        {
          name: '核销金额', //'核销金额',
          icon: 'check', //'member',
          key: 'todayCheck'
        },
        {
          name: '平台会员总数', // '平台会员数',
          icon: 'member', //'check',
          key: 'totalUserCount'
        },
        {
          name: '平台合伙人总数',
          icon: 'partner',
          key: 'distributionMemberCount'
        }
      ]
    }
  },
  created() {
    this.getUserData()
  },
  watch: {
    rowData(res) {
      this.$set(this.data, 'todayMoney', {
        value: res.salesAmount,
        yesterday: res.yesterdaySalesAmount,
        trend: res.salesAmount > res.yesterdaySalesAmount
      })
      this.$set(this.data, 'todayOrderCount', {
        value: res.payOrderCount,
        yesterday: res.yesterdayPayOrderCount,
        trend: res.payOrderCount > res.yesterdayPayOrderCount
      })
      this.$set(this.data, 'todayCheck', {
        value: res.checkedAmount,
        yesterday: res.yesterdayCheckedAmount,
        trend: res.checkedAmount > res.yesterdayCheckedAmount
      })
    }
  },
  methods: {
    getUserData() {
      this.$http.get('/boom-center-statistics-service/sysAdmin/homestatistic/generalSituation').then(res => {
        this.$set(this.data, 'distributionMemberCount', {
          value: res.distributionMemberCount,
          yesterday: res.yesterdayDistributionMemberCount,
          trend: res.distributionMemberCount > res.yesterdayDistributionMemberCount
        })
        this.$set(this.data, 'totalUserCount', {
          value: res.memberCount,
          yesterday: res.yesterdayMemberCount,
          trend: res.memberCount > res.yesterdayMemberCount
        })
      })
    }
  }
}
</script>

<style lang='less' scoped>
.overview-data {
  width: 100%;
  display: flex;
  .item-box {
    flex: 1;
    padding: 15px;
    .data-item {
      background: #fff;
      padding: 15px;
      border-radius: 15px;
      box-shadow: 0px 4px 12px -4px rgba(179, 181, 186, 0.08);
      transition: all 0.5s;
      cursor: default;
      &:hover {
        box-shadow: 4px 8px 12px 0 rgba(179, 181, 186, 0.2);
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(71, 72, 73, 1);
        font-family: HarmonyOS Sans SC;
      }
      .content {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .icon {
          width: 50px;
          height: 50px;
          border-radius: 10px;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .data {
          flex: 1;
          overflow: hidden;
          font-family: HarmonyOS Sans SC;
          .data-num {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
            color: rgba(27, 29, 33, 1);
          }
          .yesterday {
            margin-top: 4px;
            font-size: 14px;
            color: rgba(128, 129, 145, 1);
            display: flex;
            align-items: center;
            white-space: nowrap;
            img {
              margin-left: 5px;
              width: 18px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
</style>